<!--
 * @Description: AKJERRT
 * @Date: 2022-07-11 17:35:05
 * @LastEditTime: 2022-07-14 15:48:09
 * @FilePath: \HuiLiang\affair\src\views\account_statement\components\handler\confirm.vue
-->
<template>
  <a-card :border="false">
    <div class="realtor">
      <a-modal title="确认账单" width="50%" v-model="visible" :footer="null" :maskClosable="false">
        <a-form :form="form">
          <a-form-item label="账单核对" :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }">
            <a-radio-group v-model="res_data.confirmStatus" @change="onChange">
              <a-radio :value="1"> 相符 </a-radio>
              <a-radio :value="2"> 不相符 </a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="对账说明" :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }">
            <a-textarea
              v-model="res_data.statementRemark"
              :rows="6"
              placeholder="对账说明"
            ></a-textarea>
            <!--            v-decorator="['statementRemark', { rules: [{ required: checkNick, message: '请输入对账说明' }] }]"-->
          </a-form-item>
          <a-form-item label="上传附件" :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }">
            <QiniuUpload type="file" :disabled="handle == 'check'" :value.sync="res_data.statementFile"></QiniuUpload>
          </a-form-item>
        </a-form>
        <div>
          <span style="font-weight: bold">注意事项：</span><br/>
          <span>1、对账单每月一次由系统生成，请及时对账，逾期未对账影响后续在线下单（对账单产生后需30日内完成对账）</span><br/>
          <span>2、该对账单是我司与贵司通过线上平台交易的账单记录，具有法律效力</span><br/>
          <span>3、对账单提交后不可再次修改</span><br/>
        </div><br/><br/>
        <a-row type="flex" justify="end">
          <a-col>
            <!-- <a-button @click="toSubmit">打印</a-button>
            <a-button @click="toSubmit">下载</a-button>
            <a-button @click="toSubmit">暂存</a-button> -->
            <a-button :loading="loading" type="primary" @click="toDown">下载</a-button>
            <a-button :loading="loading" type="primary" @click="toSubmit('save')">暂存</a-button>
            <a-button :loading="loading" type="primary" @click="toSubmit('submit')">提交</a-button>
            <a-button :loading="loading" @click="toSubmit('close')">取消</a-button>
          </a-col>
        </a-row>
      </a-modal>
      <!-- 下载弹窗 -->
      <DownStatement ref="downStatement"></DownStatement>
    </div>
  </a-card>
</template>

<script>
import {confirmStatement, saveStatement} from "@/views/account_statement/api/DealerStatementApi";

export default {
  name: 'confirm',
  components: {
    DownStatement: () => import('./downStatement.vue'),
  },
  data() {
    return {
      handle: '',
      res_data: {
        statementIds:[],
        statementRemark: '',
        statementFile: '',
        confirmStatus: 1,
      },
      visible: false,
      loading: false,
      checkNick: false,
      form: this.$form.createForm(this, { name: 'dynamic_rule' }),
      statementId:'',
    }
  },
  methods: {
    init(row) {
      this.$nextTick(() => {
        this.form.resetFields()
        this.visible = true
        const vo = []
        // row.forEach((e) => {
        //   vo.push(e.id)
        // })
        vo.push(row.id)
        this.res_data.statementIds = vo
        this.res_data.confirmStatus = row.confirmStatus > 0 ? row.confirmStatus : 1
        this.res_data.statementRemark = row.statementRemark
        this.res_data.statementFile = row.statementFile
        this.statementId = row.id
      })
    },
    onClose() {
      this.$nextTick(() => {
        this.form.resetFields()
        this.visible = false
      })
    },
    onChange(type) {
      if (type.target.value == 2) {
        this.checkNick = true
        // this.$nextTick(() => {
        //   this.form.validateFields(['statementRemark'], { force: true })
        // })
      } else {
        this.checkNick = false
        // this.$nextTick(() => {
        //   this.form.resetFields()
        // })
      }
    },
    toSubmit(type) {
      if (type === 'close') {
        this.visible = false
        return false
      }

      if (type === 'save') {
        // this.form.validateFields((err, values) => {
        //   if (!err) {
            this.loading = true
            const param = {
              id:this.statementId,
              statementRemark:this.res_data.statementRemark,
              statementFile:this.res_data.statementFile,
              confirmStatus:this.res_data.confirmStatus
            }
            saveStatement(param).then(res => {
              if (res.code === 200) {
                this.$message.success('对账单暂存成功')
                this.visible = false
                this.$emit('reload')
                this.res_data = {
                  statementIds:[],
                  statementRemark: '',
                  statementFile: '',
                  confirmStatus: 1,
                }
              } else {
                this.$message.error(res.message)
              }
            }).catch(() => {}).finally(() => {
              this.loading = false
            })
        //   }
        // })
      }
      if (type === 'submit') {
        // this.form.validateFields((err, values) => {
        //   if (!err) {
            // this.loading = true
            // new Promise((resolve, reject) => {
            //   setTimeout(() => {
            //     this.loading = false
            //     this.visible = false
            //   }, 1000)
            // })
            console.log(JSON.parse(JSON.stringify(this.res_data)))
            // console.log('Received values of form: ', values)

            this.loading = true
            confirmStatement(this.res_data).then(res => {
              if (res.code === 200) {
                this.$message.success('对账单确认成功')
                this.visible = false
                this.$emit('reload')
                this.res_data = {
                  statementIds:[],
                  statementRemark: '',
                  statementFile: '',
                  confirmStatus: 1,
                }
              } else {
                this.$message.error(res.message)
              }
            }).catch(() => {}).finally(() => {
              this.loading = false
            })
        //   }
        // })
      }
    },

    toDown(){
      this.$refs.downStatement.init(this.statementId)
    }
  },
}
</script>
